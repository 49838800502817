import ALL from "./ALL";

export default [
  {
    code: "1",
    name: "1 - MVMT Standard",
    description: `Ergonomic upholstered seat with forward sloping edge and Integral foam bumper guard. Seat measures
      20.5" wide. With 5/8" thick (9-ply) seat board. Nominal foam thickness 2" with 4° rearward angle. 4mm (5/32")
      thick polypropylene seat pan w/ edge guard. Pro seat is adjustable from 16.4”-18.9” depth.`,
    back: ["MB", "TB"],
    base: ["A", "W", "R"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["PM", "E3"],
    footring: ["AFP", "XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO6K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO7C",
      "ISO8C",
    ],

    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],

    series: ["MVMT", "MTCL"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["MVMT"],
    upholstered: ["upholstered"],
    price: 672,
  },
  {
    code: "1F",
    name: "1F - MVMT Mounted Forward",
    description: `Ergonomic upholstered seat with forward sloping edge and Integral foam bumper guard. Seat measures 20.5"
     wide. With 5/8" thick (9-ply) seat board. Nominal foam thickness 2" with 4° rearward angle. Functional seat depth
     of the 1F seat is 18".`,
    back: ["MB", "TB"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["T", "FFAC"],
    footring: ["AFP", "XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO3",
      "ISO6K",
      "ISO5K",
      "ISO3K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO7C",
      "ISO8C",
    ],

    vinylUnderwrap: ALL,
    memoryFoam: ["X"],

    series: ["MTCL", "MTHD"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["MVMT"],
    upholstered: ["upholstered"],
    price: 339,
  },
  {
    code: "1B",
    name: "1B - MVMT Mounted Back",
    description: `Ergonomic upoholstered seat with forward sloping edge and Integral foam bumper guard. Seat measures
      20.5" wide. With 5/8 thick (9-ply) seat board. Nominal foam thickness 2" with 4° rearward angle. Functional seat
      dept of the 1B seat is 16.4".`,
    back: ["MB", "TB"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["T", "FFAC"],
    footring: ["AFP", "XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO6K",
      "ISO5K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO7C",
      "ISO8C",
    ],

    vinylUnderwrap: ALL,
    memoryFoam: ["X"],

    series: ["MTCL", "MTHD"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["MVMT"],
    upholstered: ["upholstered"],
    price: 339,
  },

  {
    code: "A",
    name: "A - Contoured",
    description: `Generously proportioned saddle-shaped seat is contoured and features a waterfall front for added comfort; measures 21" wide x 19" deep X 3" thick.`,
    back: ["T", "E", "M", "P", "S", "B"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "Z"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["AM", "BT", "EE", "EB", "Z", "Scepter"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["foundation"],
    upholstered: ["upholstered"],
    price: 292,
  },
  {
    code: "B",
    name: "B - Bio",
    description: `Concave upholstered seat with internal seat board bumperguard; measures 18.5" wide x 16.5" deep x 3" thick.`,
    back: ["T", "E", "M", "B"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ["X"],
    series: ["BT", "AM", "EE", "EB"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["foundation"],
    upholstered: ["upholstered"],
    price: 162,
  },
  {
    code: "E",
    name: "E - Ergo",
    description: `Ergonomic upholstered seat with four-way contouring and internal seat board bumperguard; measures 18.5" wide x 17" deep x 3" thick.`,
    back: ["T", "E", "M", "B"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["EE", "AM", "BT", "EB"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["foundation"],
    upholstered: ["upholstered"],
    price: 231,
  },
  {
    code: "F",
    name: "F - Sewn Contoured",
    description: `Saddle-shaped contoured seat with sewn seams upholstery, waterfall front and internal seat board bumperguard; measures 21" wide x 19" deep x 3" thick.`,
    back: ["P", "S"],
    base: ["A", "S", "R", "W", "P"],
    height: ALL,
    casters: ["RC", "RK", "HG", "IPR"],
    control: ["Z", "IP"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA", "ISAS"],
    performance: ["K", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["Z", "IP"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["foundation"],
    upholstered: ["upholstered"],
    price: [
      {
        component: "base",
        code: ["P"],
        price: 0,
      },
      {
        component: "base",
        code: ALL,
        price: 326,
      },
    ],
  },
  // {
  //   code: "H",
  //   name: "H - Sewn Ergo",
  //   description: `Ergonomic seat with sewn seams upholstery, waterfall front and internal seat board bumperguard; measures 18.5" wide x 17" deep x 3" thick.`,
  //   back: ["C", "L"],
  //   base: ["A", "S", "R", "W"],
  //   height: ALL,
  //   casters: ["RC", "RK", "HG"],
  //   control: ["T", "FFAC", "EXE"],
  //   footring: ALL,
  //   arm: ["ARS", "PSAS", "XA"],
  //   performance: ["K", "C", "06"],
  //   vinylUnderwrap: ALL,
  //   memoryFoam: ALL,
  //   series: ["HC", "HL"],
  //   upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
  //   style: ["foundation"],
  //   upholstered: ["upholstered"],
  //   price: 262,
  // },
  // {
  //   code: "VF:F",
  //   name: "VF:F - Vacuum Formed Contoured",
  //   description: `Saddle-shaped contoured seat with vacuum formed vinyl upholstery, waterfall front and internal seat board bumperguard; measures 21" wide x 19" deep x 3" thick.`,
  //   back: ["VF:C", "VF:L"],
  //   base: ["A", "S", "R", "W"],
  //   height: ALL,
  //   casters: ["RC", "HG"],
  //   control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
  //   footring: ALL,
  //   arm: ["ARS", "PSAS", "XA"],
  //   performance: ["ISO6", "ISO5", "ISO4", "C", "ISO3", "06", "ISO8", "ISO7", "ISO8C", "ISO7C"],
  //   vinylUnderwrap: ["X"],
  //   memoryFoam: ["X"],
  //   series: ["VF:FC", "VF:FL"],
  //   upholstery: ["XBLK", "XBLU"],
  //   style: ["foundation"],
  //   upholstered: ["upholstered"],
  //   price: 326,
  // },
  // {
  //   code: "VF:H",
  //   name: "VF:H - Vacuum Formed Ergo",
  //   description: `Ergonomic seat with vacuum formed vinyl upholstery, waterfall front and internal seat board bumperguard; measures 18.5" wide x 17" deep x 3" thick.`,
  //   back: ["VF:C", "VF:L"],
  //   base: ["A", "S", "R", "W"],
  //   height: ALL,
  //   casters: ["RC", "HG"],
  //   control: ["T", "FFAC", "EXE"],
  //   footring: ALL,
  //   arm: ["ARS", "PSAS", "XA"],
  //   performance: ["ISO6", "ISO5", "ISO4", "C", "ISO3", "06", "ISO8", "ISO7", "ISO8C", "ISO7C"],
  //   vinylUnderwrap: ["X"],
  //   memoryFoam: ["X"],
  //   series: ["VF:HC", "VF:HL"],
  //   upholstery: ["XBLK", "XBLU"],
  //   style: ["foundation"],
  //   upholstered: ["upholstered"],
  //   price: 262,
  // },
  {
    code: "U",
    name: "U - Urethane Chair",
    description: `Black, self-skinned urethane seat with waterfall front, shaped rear edge and 0.09375" ventilating ribs; measures 18.5" wide x 17" deep x 1.5" thick.`,
    back: ["U"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["T", "FFAC"],
    footring: ALL,
    arm: ["ARS", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO6K",
      "C",
      "06",
      "ISO8",
      "ISO7",
      "ISO8K",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["UU"],
    upholstery: ["BLACK"],
    style: ["foundation"],
    upholstered: ["nonUpholstered"],
    price: 191,
  },
  {
    code: "G",
    name: "G - ArmorSeat",
    description: `Ergonomic polypropylene seat with waterfall front, UV inhibitor and antimicrobial properties; measures 17.5" wide x 15.5" deep.`,
    back: ["G"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC"],
    footring: ALL,
    arm: ["XA"],
    performance: [
      "K",
      "ISO6",
      "C",
      "ISO4",
      "06",
      "ISO6K",
      "ISO4K",
      "ISO8",
      "ISO7",
      "ISO8K",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["GG"],
    upholstery: ["BLACK", "BLUE"],
    style: ["foundation"],
    upholstered: ["nonUpholstered"],
    price: 125,
  },
  {
    code: "W",
    name: "W - Plywood",
    description: `Maple 7-ply plywood seat with waterfall front; measures 16.5" wide x 15.25" deep x 0.5" thick.`,
    back: ["W"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG"],
    control: ["T"],
    footring: ALL,
    arm: ["XA"],
    performance: ["06", "C"],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["WW"],
    upholstery: ["MAPLE"],
    style: ["foundation"],
    upholstered: ["nonUpholstered"],
    price: 207,
  },
  // {
  //   code: "K",
  //   name: "K - Polyshell",
  //   description: `One-piece high-density polypropylene seat/back; measures 18.5" wide x 17" deep x 18.5" high.`,
  //   back: ["X"],
  //   base: ["A", "S", "R", "W"],
  //   height: ALL,
  //   casters: ["RC", "HG"],
  //   control: ["P"],
  //   footring: ALL,
  //   arm: ["XA"],
  //   performance: ["06", "ISO5", "C", "ISO8", "ISO7", "ISO8C", "ISO7C"],
  //   vinylUnderwrap: ["X"],
  //   memoryFoam: ["X"],
  //   series: ["KX"],
  //   upholstery: ["BLACK", "BLUE"],
  //   style: ["foundation"],
  //   upholstered: ["nonUpholstered"],
  //   price: 164,
  // },
  {
    code: "T",
    name: "T - Round Steel Stool",
    description: `14-gauge steel seat with formed flange and smooth edges; 13" diameter.`,
    back: ["X"],
    base: ["A", "S", "R", "W", "G"],
    height: ALL,
    casters: ["RC", "RK", "HG", "GL"],
    control: ["S"],
    footring: ALL,
    arm: ["XA"],
    performance: ["06", "C", "ISO4", "ISO4K", "ISO5", "ISO5K", "ISO6", "ISO6K", "K"],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["TX", "TXG"],
    upholstery: ["BLACK"],
    style: ["foundation"],
    upholstered: ["nonUpholstered"],
    price: 85,
  },
  {
    code: "C",
    name: "C - Round Urethane Stool",
    description: `Black, self-skinned urethane seat, 14" diameter, 1.5" thick.`,
    back: ["X"],
    base: ["A", "S", "R", "W", "G"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["S"],
    footring: ALL,
    arm: ["XA"],
    performance: ["06", "C", "ISO6"],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["CX"],
    upholstery: ["BLACK"],
    style: ["foundation"],
    upholstered: ["nonUpholstered"],
    price: 182,
  },
  {
    code: "R",
    name: "R - Round",
    description: `Upholstered seat with high-resilience foam cushioning and internal seat board bumperguard; 16" diameter, 3.5" thick.`,
    back: ["T", "X"],
    base: ["A", "S", "R", "W", "C"],
    height: ALL,
    casters: ["RC", "RK", "HG", "CRC"],
    control: ["T", "S", "FA"],
    footring: ALL,
    arm: ["XA"],
    performance: ["K", "C", "06"],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["2A", "RX", "RT"],
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "MBLU", "MBLK", "GEBONY", "GNAVY"],
    style: ["foundation"],
    upholstered: ["upholstered"],
    price: 183,
  },
  {
    code: "VF:R",
    name: "VF:R - Vacuum Formed Round",
    description: `Seat with vacuum formed vinyl upholstery, high-resilience foam cushioning and internal seat board bumperguard; 16" diameter, 3.5" thick.`,
    back: ["T", "X"],
    base: ["A", "S", "R", "W", "C"],
    height: ALL,
    casters: ["RC", "HG", "CRC"],
    control: ["T", "S", "FA"],
    footring: ALL,
    arm: ["XA"],
    performance: ["ISO6", "C", "06"],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["2A", "RX", "RT"],
    upholstery: ["XBLK"],
    style: ["foundation"],
    upholstered: ["upholstered"],
    price: 183,
  },
  {
    code: "TX",
    name: "AJ - Fixed Height Stool",
    description: `Stool with 14-gauge steel seat with formed flange and smooth edges; 13" diameter.`,
    back: ["X"],
    base: ["A18", "A24", "A30"],
    height: ALL,
    casters: ["GL"],
    control: ["X"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["ISO4", "C", "06"],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["AJ"],
    upholstery: ["BLACK"],
    style: ["foundation"],
    upholstered: ["nonUpholstered"],
  },
  {
    code: "L",
    name: "L - Sit/Stand Seat",
    description: `Black, self-skinned urethane seat with built-in handle; measures 13" wide x 13" deep.`,
    back: ["X"],
    base: ["S"],
    height: ["M", "H"],
    casters: ["HG"],
    control: ["L"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["06", "C"],
    vinylUnderwrap: ["X"],
    memoryFoam: ["X"],
    series: ["GX"],
    upholstery: ["BLACK"],
    style: ["foundation"],
    upholstered: ["nonUpholstered"],
    price: 515,
  },
];
